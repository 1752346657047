import { h, Component } from 'preact'
import { Router } from 'preact-router'

import Header from './header'
import Footer from './footer'

// Code-splitting is automated for routes
import Home from '../routes/home'
import Create from '../routes/create'
import About from '../routes/about'
import Loggedin from '../routes/loggedin'
// import Profile from '../routes/profile'

export default class App extends Component {
  /** Gets fired when the route changes.
   *  @param {Object} event    "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url  The newly routed URL
   */
  handleRoute = e => {
    this.currentUrl = e.url
  }

  render () {
    return (
      <div id='app'>
        <Header />
        <Router onChange={this.handleRoute}>
          <Home path='/' />
          <Create path='/create/' />
          <About path='/about/' />
          <Loggedin path='/loggedin/' />
          {/* <Profile path='/profile/' user='me' />
          <Profile path='/profile/:user' /> */}
        </Router>
        <Footer />
      </div>
    )
  }
}
