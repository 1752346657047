import { h, Component } from 'preact'
import { Link } from 'preact-router/match'
import style from './style.css'
import { login, logout, isLoggedIn } from '../../utils/authService'

export default class Header extends Component {
  state = {
    isLogged: isLoggedIn()
  }

  execLogin = e => {
    login()
    this.setState({isLogged: isLoggedIn()})
    e.preventDefault()
  }

  execLogout = e => {
    logout()
    this.setState({isLogged: isLoggedIn()})
    e.preventDefault()
  }

  render (props, state) {
    return (
      <header class={style.header}>
        <a href='/'>
          <div class={style.navTitle}>
            <img src='../../assets/icons/favicon-32x32.png' />
            <h1>dot quaren</h1>
          </div>
        </a>
        <input type='checkbox' class={style.burgerbtn}>X</input>
        <div class={style.burgerspans}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav>
          <Link activeClassName={style.active} href='/'>Home</Link>
          {this.state.isLogged && <Link activeClassName={style.active} href='/create'>Create</Link>}
          <Link activeClassName={style.active} href='/about'>About</Link>
          <div onClick={this.state.isLogged ? this.execLogout : this.execLogin} class={style.loginBtn}>{this.state.isLogged ? 'Logout' : 'Login'}</div>
        </nav>
      </header>
    )
  }
}
