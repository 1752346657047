import { h } from 'preact'
import style from './style.css'

const Footer = () => (
  <footer class={style.footer}>
    <div class={style.falticon}>Icons made by
      <a href='https://www.flaticon.com/authors/freepik' title='Freepik'> Freepik </a>
      from
      <a href='https://www.flaticon.com/' title='Flaticon'> www.flaticon.com</a></div>
  </footer>
)

export default Footer
