import decode from 'jwt-decode'
import auth0 from 'auth0-js'

import { redirectURL } from '../.conf'

const ID_TOKEN_KEY = 'id_token'
const ACCESS_TOKEN_KEY = 'access_token'

const CLIENT_ID = 'Tm0f1o7pamb2bpkNrLJgCQMomjrJJ8y8'
const CLIENT_DOMAIN = 'quaren.eu.auth0.com'
const REDIRECT = redirectURL
// const SCOPE = ''
const AUDIENCE = 'https://blog.quaren.co.uk/api'

const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN
})

export function login () {
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
    audience: AUDIENCE,
    // scope: SCOPE
  })
}

export function logout () {
  clearIdToken()
  clearAccessToken()
  window.location.href = '/' 
}

export function requireAuth (nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' })
  }
}

export function getIdToken () {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(ID_TOKEN_KEY)
  }
}

export function getAccessToken () {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY)
  }
}

function clearIdToken () {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(ID_TOKEN_KEY)
  }
}

function clearAccessToken () {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}

function getParameterByName (name) {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export function setAccessToken () {
  const accessToken = getParameterByName('access_token')
  if (typeof window !== "undefined") {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
}

export function setIdToken () {
  const idToken = getParameterByName('id_token')
  if (typeof window !== "undefined") {
    window.localStorage.setItem(ID_TOKEN_KEY, idToken)
  }
}

export function isLoggedIn () {
  const idToken = getIdToken()
  // return !!idToken && !isTokenExpired(idToken)
  return !!idToken
}

function getTokenExpirationDate (encodedToken) {
  const token = decode(encodedToken)
  if (!token.exp) return null
  const date = new Date(0)
  date.setUTCSeconds(token.exp)
  return date
}

function isTokenExpired (token) {
  const expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}